<template>
  <a-layout id="app-menu">

    <a-layout-sider theme="light" class="layout-sider">
      <div class="list">

        <div class="action-item">
          <input class="login-input" maxlength="20" v-model="seachvalue" placeholder="搜索" />
          <div class="seach-button" @click="seachList"></div>
          <div class="action-edit" :class="add ? 'action-edit-avicat' : ''" @click="setAdd">
          </div>
        </div>

        <div class="list-plant" ref="listplant" id="listplant" @mousedown="down" @mousemove="move" @mouseup="up" @mouseleave="leave">
          <!-- 新好友提示 -->
          <div class="list-item" v-if="pageid == 3" v-for="item in newFileds" :key="item.from" @click="actionFlieds(item)" @contextmenu.prevent="openMenu($event, 0)">
            <div class="item-header-ruand">
              <img src="~@/assets/yq.png" />
              <div class="new-dot"></div>
            </div>
            <div class="item-body">
              <div class="item-nickname">
                <div>新的好友</div>
              </div>
              <div class="item-dec">
                <div class="item-dec-text">{{ item.status }}</div>
              </div>
            </div>
          </div>
          <!-- 新入群申请 -->
          <div class="list-item" v-if="pageid == 2" v-for="item in newGroups" :key="item.from" @click="actionGroups(item)" @contextmenu.prevent="openMenu($event, 0)">
            <div class="item-header-ruand">
              <img src="~@/assets/yq.png" />
              <div class="new-dot"></div>
            </div>
            <div class="item-body">
              <div class="item-nickname">
                <div>群审批</div>
              </div>
              <div class="item-dec">
                <div class="item-dec-text">{{ item.reason }}</div>
              </div>
            </div>
          </div>


          <!-- 列表 -->
          <div class="list-item" :class="itemId == item.id ? 'list-item-active' : ''" v-if="!(item.chatType == 'groupchat' && pageid == 1)" v-for="item in listItem" :key="item.id" @click="setItem(item.id)" @contextmenu.prevent="openMenu($event, item)">
            <div class="item-header">
              <img class="item-header-img" :src="item.avatarurl" v-if="pageid == 1 || pageid == 3" />
              <img class="item-header-img" src="../assets/groupTheme.png" v-if="pageid == 2" />
              <!-- <div v-if="pageid == 2" class="header-items" :id="item.id">
              <img v-for="(edi,index) in item.avatarurls" :src="edi" :width="getHw(item)" :height="getHw(item)" :key="edi+index"/>
            </div> -->
              <div class="new-dot" v-if="item.unread_num > 0 && pageid == 1 && item.mdtype != 'NONE'"></div>
              <div class="new-dot" v-if="item.unread_num > 0 && pageid != 1 && item.mdtype != 'NONE'"></div>
            </div>
            <div class="item-body">
              <div class="item-nickname" :class="pageid == 3 ? 'mtop20' : ''">
                <div v-if="pageid == 2" style="display: flex;"><span class="groupname">{{ item.groupnameold !=''?item.groupnameold:item.groupname }}</span>
                  <span>({{ item.affiliationsCount }})</span>
                </div>
                <div v-if="pageid == 3">{{ item.nicknameold!=""?item.nicknameold : (item.nickname ? item.nickname : item.id) }}</div>
                <div v-if="pageid == 1">{{ item.nicknameold!=""?item.nicknameold : (item.nickname ? item.nickname : item.id) }}</div>
                <div v-if="pageid != 3" class="time">{{ item.time }}</div>
              </div>
              <div class="item-dec" v-if="pageid != 3">
                <div class="item-dec-text">{{ item.msg }}</div>
                <div class="item-dot" v-if="item.unread_num && item.mdtype != 'NONE'">{{ item.unread_num }}</div>
                <div class="item-mdr" v-if="item.mdtype == 'NONE'">
                  <img src="~@/assets/mdr.png">
                </div>
                <div class="item-mdr-zd" v-if="getZdStatus(item)">
                  置顶
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </a-layout-sider>
    <div v-if="right" class="right-click" :class="this.pageid == 3 ? 'right-click-fileds' : ''" :style="this.pageid == 2 ? 'height:123px;' : ''" ref="rightClick">
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(1)">{{ getZdName() }}</div>
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(2)">标记已读</div>
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(3)">{{ getMdName() }}</div>
      <div v-if="this.pageid != 3 && this.pageid != 2" class="p-text" @click="aciton(6)">{{ this.pageid == 2 ?
        getActionName() : '添加备注' }}</div>
      <div v-if="this.pageid != 3" class="f-splt"></div>
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(4)">{{ this.pageid == 2 ? getActionName() : '删除聊天' }}
      </div>

      <div class="p-text" v-if="this.pageid == 3" @click="aciton(5)">删除好友</div>
    </div>
    <div v-if="add" class="add-plant">
      <div class="p-text" @click="openFapl(1)">加好友/群</div>
      <div class="p-text" @click="openFapl(2)">创建群组</div>
    </div>
    <a-layout>
      <a-layout-content>
        <router-view :uid="itemId" :chatType="type" @sendM="updateList" @updateInfos="updateInfos" @gotoGroup="gotoGroup" />
      </a-layout-content>
    </a-layout>
    <div v-if="add || right" class="mb" @click="closeMb" @contextmenu.prevent="closeMb"></div>

    <a-modal v-model="visible" title="加好友/群" :footer="null" width="244px" :centered="true">
      <div class="ftitle">用户ID/群ID</div>
      <div class="finput">
        <input class="login-input-f" v-model="fuserId" />
      </div>
      <div style="display: flex; justify-content: space-between;">
        <button class="login-button-queren" @click="okAdd">查询</button>
        <button class="login-button-quxiao" @click="ceacl">取消</button>
      </div>
    </a-modal>

    <a-modal v-model="groupvisible" :title="null" :footer="null" :closable="false" width="390px" :centered="true">
      <div class="grouptitle">
        <img class="groupback" src="~@/assets/back.png" v-if="groupindex == 2" @click="backgroup">
        <div class="groupback" v-if="groupindex == 1"></div>
        <div class="grouptitle-text">创建群组</div>
        <img class="groupclose" src="@/assets/main-close.png" @click="ceaclCreateGroup">
      </div>
      <div class="groupline"></div>
      <div v-if="groupindex == 1" style="margin-top: 16px;">
        <div class="ftitle">创建群组</div>
        <div class="finput">
          <input class="login-input-f-ix" v-model="groupname" />
        </div>
        <div class="ftitle" style="margin-top: 16px;">群简介</div>
        <div class="finput">
          <textarea class="login-input-f-ex" v-model="groupdec"></textarea>
        </div>
        <div class="ftitle" style="margin-top: 16px;">群类型</div>
        <div class="finput" style="margin-top: 16px;">
          <a-radio-group v-model="groupclass">
            <a-radio :value="1">私有群</a-radio>
            <a-radio :value="2">公有群</a-radio>
          </a-radio-group>
        </div>
        <div class="ftitle" style="margin-top: 16px;">群审批类型</div>
        <div class="finput" style="margin-top: 16px;">
          <a-radio-group v-model="grouptype">
            <a-radio :value="1">审批</a-radio>
            <a-radio :value="2">不审批</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div v-if="groupindex == 2" style="margin-top: 16px; display: flex;">
        <div class="group-left">
          <div class="seachGroupUser">
            <input class="seachgroupuser-input" maxlength="20" v-model="seachvalue" placeholder="搜索" />
            <div class="seach-button-group" @click="seachList"></div>
          </div>
          <div class="ftitle" style="margin-top: 8px;">群成员</div>
          <div class="group-user-list">
            <a-collapse v-model="activeKey" :expand-icon-position="'right'">
              <a-collapse-panel key="1" header="最近联系人">
                <a-checkbox-group v-model="lsuserGroups" @change="changeGroupUsers">
                  <div v-for="item in hhlist" :key="item.id"><a-checkbox :value="item">{{ item.nickname ? item.nickname :
                    item.id }}</a-checkbox></div>
                </a-checkbox-group>
                <template #extra><setting-outlined @click="handleClickUser" /></template>
              </a-collapse-panel>
              <a-collapse-panel key="2" header="好友">
                <a-checkbox-group v-model="hyuserGroups" @change="changeGroupUsers">
                  <div v-for="item in hylist" :key="item.id"><a-checkbox :value="item">{{ item.nickname ? item.nickname :
                    item.id }}</a-checkbox></div>
                </a-checkbox-group>
                <template #extra><setting-outlined @click="handleClickUser" /></template>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
        <div class="group-right">
          <div class="ftitle" style="margin-top: 0px;">已选联系人</div>
          <div class="useUser" v-for="(item, index) in useUserList" :key="item.id">
            <div class="useUser-name">{{ item.nickname ? item.nickname : item.id }}</div>
            <img class="useUser-icon" src="@/assets/user-delete.png" @click="deleteUserForUserList(index)">
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <button class="login-button-quxiao" @click="ceaclCreateGroup">取消</button>
        <button class="login-button-queren" @click="okAddCreateGroup">{{ groupindex == 1 ? '下一步' : '创建群组' }}</button>
      </div>
    </a-modal>

    <a-modal v-model="addFiledsvisible" :title="pageid == 2 ? '群组请求' : '好友请求'" :footer="null" width="244px" :centered="true">
      <div class="ftitle" style="color:#222;">{{ vbtitle }}</div>
      <div style="display: flex; justify-content: space-between;">
        <button class="login-button-queren" @click="okAddFileds">同意</button>
        <button class="login-button-quxiao" @click="ceaclFileds">拒绝</button>
      </div>
    </a-modal>
    <a-modal v-model="notePlat" title="修改用户备注" :footer="null" width="244px" :centered="true">
      <div class="ftitle" style="color:#222;">用户备注</div>
      <div style="margin-top: 10px;">
        <a-input v-model="userNote"></a-input>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <button class="login-button-queren" @click="okAddNote">确定</button>
        <button class="login-button-quxiao" @click="ceaclNote">取消</button>
      </div>
    </a-modal>
  </a-layout>
</template>
<script>
import { EaseChatClient } from '@/IM/initwebsdk'
import storage from 'store2'
import { message } from 'ant-design-vue';
import dateFormat from '@/utils/dateFormater'
import { Modal } from 'ant-design-vue';
import { notification } from 'ant-design-vue';
import { usePlayRing } from '@/hooks'
import { ConversationUtils } from '@/utils/ConversationUtils'
import { MessageUtils } from '@/utils/MessageUtils'
import { ipcApiRoute, requestHttpGet,requestHttpPostByOnline } from '@/api/main'
import apiUrl from '@/utils/config'
var titleInit = document.title, isShine = true;
window.onfocus = function () {
  isShine = false;
};
window.onblur = function () {
  isShine = true;
};

// for IE
document.onfocusin = function () {
  isShine = false;
};
document.onfocusout = function () {
  isShine = true;
};
export default {

  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      seachvalue: "",
      add: false,
      right: false,
      flag: false,
      listItem: [],
      downY: 0,
      itemId: "",
      scrollTop: 0,
      acitonId: 0,
      pageid: 1,
      visible: false,
      fuserId: "",
      ginfo: null,
      newFileds: [],
      newGroups: [],
      addFiledsvisible: false,
      vbtitle: '',
      filedsItem: null,
      type: 'singleChat',
      acitonItems: null,
      groupvisible: false,
      groupname: "",
      groupdec: "",
      grouptype: 1,
      groupclass: 1,
      groupindex: 1,
      activeKey: "1",
      lsuserGroups: [],
      hyuserGroups: [],
      hylist: [],
      hhlist: [],
      useUserList: [],
      groupNum: 0,
      groupSize: 20,
      notePlat: false,
      userNote: "",
      userNoteId: ""
    };
  },
  watch: {
    id: function (e) {
      console.log(e)
      this.groupNum = 0
      this.menuHandle();
    },
  },
  created() {

  },
  mounted() {
    let that = this
    window.addEventListener('scroll', this.scrollDs, true)
    notification.config({
      placement: 'topLeft',
      top: '50px',
      duration: 10,
    })
    //初始化消息
    this.groupNum = 0
    this.menuHandle();
    console.log("这里刷新")
    this.$bus.$off('newMessage')
    this.$bus.$on("newMessage", (data) => {
      console.log("新消息：", data);
      console.log("新消息：", this.itemId);
      //发送信
      if (data.chatType == "groupChat") {
        if (this.itemId == data.to) {
          data.deliver = true
        }
        that.changeShow(2)
      } else {
        if (this.itemId == data.from) {
          data.deliver = true
          MessageUtils.sendMessageGoBack(data.from, data.type, this)
        }
        that.changeShow(1)
      }
      if (data.from == storage.get("ImAcount")) {
        data.fromtype = "local"
      }
      that.updateList(data)
    })
    this.$bus.$off('RestFileds')
    this.$bus.$on("RestFileds", async (data) => {
      if (that.pageid == 3) {
        that.listItem = []
        if (storage.get('newFiledsList')) {
          let fileds = storage.get('newFiledsList')
          that.newFileds = JSON.parse(fileds)
        }
        console.log(that.newFileds)
        //获取好友列表
        let res = await EaseChatClient.getContacts()
        let userinfo = await EaseChatClient.fetchUserInfoById(res.data)
        Object.keys(userinfo.data).forEach(item => {
          console.log(item)
          that.listItem.push({
            id: item,
            nickname: userinfo.data[item]['nickname'],
            avatarurl: userinfo.data[item]['avatarurl']
          })
        });
      }
      if (that.listItem.length > 0) {
        that.setItem(that.listItem[0].id)
      } else {
        that.setItem(0)
      }
    })
    this.$bus.$off('joinGroups')
    this.$bus.$on("joinGroups", async (data) => {
      console.log("加入群提示", data);
      //获取群组信息
      // let groupInfo = await EaseChatClient.getGroupInfo({groupId:data.gid}) 
      //增加群组会话
      // that.addGroupMessage(groupInfo);
      await ConversationUtils.setGroupToLocal(data.gid, this.listItem, this, this.pageid)

      message.success("您已成功加入" + data.groupName + "群组！")
    })
    this.$bus.$off('RemoveGroups')
    this.$bus.$on("RemoveGroups", async (data) => {
      console.log("踢出群组群提示", data);
      //获取群组信息
      if (this.pageid == 2) {
        that.leaveGroupMessage(data.gid);
      }
      message.success("您已成功离开群组！")
    })
    this.$bus.$off('leaveGroups')
    this.$bus.$on("leaveGroups", async (data) => {
      console.log("离开群组群提示", data);
      //获取群组信息
      let groupInfo = await EaseChatClient.getGroupInfo({ groupId: data.gid })
      if (this.pageid == 2) {
        that.updateGroupMessage(groupInfo);
      }
      message.success("群成员离开" + groupInfo.data[0].name + "群组！")
    })
    this.$bus.$off('pushMNewGroupsmemberJoinSuccess')
    this.$bus.$on("pushMNewGroupsmemberJoinSuccess", async (data) => {
      console.log("新成员加入群组提示", data);
      let groupInfo = await EaseChatClient.getGroupInfo({ groupId: data.gid })
      //获取群组信息
      if (this.pageid == 2) {
        that.updateGroupMessage(groupInfo);
      }
      message.success("新成员加入" + groupInfo.data[0].name + "群组！")
    })
    this.$bus.$off('pushMNewGroupsjoinPublicGroupSuccess')
    this.$bus.$on("pushMNewGroupsjoinPublicGroupSuccess", async (data) => {
      console.log("加入群组提示", data);
      let groupInfo = await EaseChatClient.getGroupInfo({ groupId: data.gid })
      //获取群组信息
      if (this.pageid == 2) {
        that.addGroupMessage(groupInfo);
      }
      message.success("加入" + groupInfo.data[0].name + "群组成功！")
    })
    this.$bus.$off('pushMNewGroupsjoinPublicGroupDeclined')
    this.$bus.$on("pushMNewGroupsjoinPublicGroupDeclined", async (data) => {
      console.log("加入群组提示", data);
      let groupInfo = await EaseChatClient.getGroupInfo({ groupId: data.gid })
      message.success("加入" + groupInfo.data[0].name + "群组失败！")
    })

    this.$bus.$off('pushMNewGroupsdeleteGroupChat')
    this.$bus.$on("pushMNewGroupsdeleteGroupChat", async (data) => {
      console.log("解散群组提示", data);
      if (this.pageid == 2) {
        that.leaveGroupMessage(data.gid);
      }
      message.success("已退出群聊")
    })


    this.$bus.$off('addGroups')
    this.$bus.$on("addGroups", async (data) => {
      console.log("群审批：", data);
      //增加弹窗
      let userinfo = await EaseChatClient.fetchUserInfoById(data.from)
      let itemdlo = {
        nickname: userinfo.data[data.from]['nickname'],
        avatarurl: userinfo.data[data.from]['avatarurl'],
        content: data.reason
      }
      console.log(itemdlo)
      that.messageTonGroup(itemdlo)
      that.changeShow(2)
      if (storage.get('newGroupsList')) {
        let fileds = storage.get('newGroupsList')
        this.newGroups = JSON.parse(fileds)
      }
      console.log(this.newGroups)
      let isp = true
      that.newGroups.forEach(item => {
        if (item.from == data.from) {
          isp = false
        }
      });
      if (isp) {
        that.newGroups.push(data)
      }
      console.log(that.newGroups)
      //保存到本地
      storage.set('newGroupsList', JSON.stringify(that.newGroups))
    })
    this.$bus.$off('addFileds')
    this.$bus.$on("addFileds", async (data) => {
      console.log("新好友申请：", data);
      //增加弹窗
      let userinfo = await EaseChatClient.fetchUserInfoById(data.from)
      let itemdlo = {
        nickname: userinfo.data[data.from]['nickname'],
        avatarurl: userinfo.data[data.from]['avatarurl'],
        content: data.status
      }
      console.log(itemdlo)
      that.messageTonFileds(itemdlo)
      that.changeShow(3)
      if (storage.get('newFiledsList')) {
        let fileds = storage.get('newFiledsList')
        this.newFileds = JSON.parse(fileds)
      }
      console.log(this.newFileds)
      let isp = true
      that.newFileds.forEach(item => {
        if (item.from == data.from) {
          isp = false
        }
      });
      if (isp) {
        that.newFileds.push(data)
      }
      console.log(that.newFileds)
      //保存到本地
      storage.set('newFiledsList', JSON.stringify(that.newFileds))
    })
    // this.$refs.listplant.addEventListener('scroll', this.handleScroll)

  },
  destroyed() {
    // this.$refs.listplant.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async okAddNote() {
      console.log(this.userNote)
      console.log(this.userNoteId)
      if (this.userNoteId == '') {
        //新增  apiUrl
        await requestHttpPostByOnline(apiUrl+'/admin-api/supplier/conversation-remark/create', {
          conversationId: this.acitonItems.id, userImId: storage.get("ImAcount"), name: this.userNote
        })
      } else {
        //修改 apiUrl
        await requestHttpPostByOnline(apiUrl+'/admin-api/supplier/conversation-remark/update', {
          id: this.userNoteId, name: this.userNote
        })
      }
      this.acitonItems.nickname = this.userNote
      storage.set('header-name-bz' + this.acitonItems.id, this.userNote)
      storage.set('header-name' + this.acitonItems.id, this.userNote)
      //更新添加备注
      let res = await this.$messageDb.getItem("corlist")
      res.forEach(e => {
        if (e.id == this.acitonItems.id) {
          e.nicknameold = this.userNote
        }
      });
      await this.$messageDb.setItem("corlist", res)
      this.notePlat = false
      this.userNote = ''
      this.userNoteId = ''
    },
    ceaclNote() {
      this.notePlat = false
      this.userNote = ''
      this.userNoteId = ''
    },
    handleScroll(e) {
      console.log("执行加载")
      // if(e.target.scrollTop+e.target.clientHeight >= e.target.scrollHeight){
      //   console.log("进底加载");
      //   //this.menuHandle()
      //   if(this.id == 'main'){
      //     this.$route.params.id = 1
      //     this.type = "singleChat"
      //   }
      //   if(this.id == 'crowd'){
      //     this.$route.params.id = 2
      //     this.type = "groupChat"
      //   }
      //   if(this.id == 'friend'){
      //     this.$route.params.id = 3
      //     this.type = "singleChat"
      //   }
      //   this.pageid = this.$route.params.id
      //   if(this.pageid == 2){
      //     this.groupNum ++;
      //     this.menuHandle();
      //   }
      //   if(this.pageid == 1){
      //     console.log(this.listItem[this.listItem.length-1].ids)
      //     this.groupNum = this.listItem[this.listItem.length-1].ids
      //     if(this.groupNum!= ''){
      //       this.menuHandle();
      //     }

      //   }
      // }
    },
    async updateGroupMessage(groupInfo) {
      let groupi = groupInfo.data[0]
      // let avatarurls = await ConversationUtils.getGroupImageFor(groupi);
      this.listItem.forEach((e, i) => {
        if (e.id == groupi.id) {
          e.affiliationsCount = groupi.affiliations_count
          // e.avatarurls = avatarurls
        }
      });
      let res = await this.$messageDb.getItem('grouplist')
      res.forEach(e => {
        if (e.id == groupi.id) {
          e.affiliationsCount = groupi.affiliations_count
        }
      });
      await this.$messageDb.setItem('grouplist', res)
    },
    async addGroupMessage(groupInfo) {
      let groupi = groupInfo.data[0]
      console.log(groupi)
      this.listItem.push({
        chatType: "groupChat",
        id: groupi.id,
        groupname: groupi.name,
        role: groupi.owner,
        disabled: groupi.disabled,
        affiliationsCount: groupi.affiliations_count
      })

    },
    async leaveGroupMessage(groupId) {
      let index = -1

      this.listItem.forEach((e, i) => {
        if (e.id == groupId) {
          index = i
        }
      });
      if (index >= 0) {
        this.listItem.splice(index, 1)
      }
      if (this.itemId == groupId) {
        if (this.listItem.length > 0) {
          this.setItem(this.listItem[0].id)
        } else {
          this.setItem(0)
        }
      }
      //获取数据
      let useritem = await this.$messageDb.getItem("corlist")
      let groupitem = await this.$messageDb.getItem("grouplist")
      console.log(useritem)
      console.log(groupId)
      useritem.forEach((e, i) => {
        if (e.id == groupId) {
          useritem.splice(i, 1)
        }
      })
      groupitem.forEach((e, i) => {
        if (e.id == groupId) {
          groupitem.splice(i, 1)
        }
      })
      await this.$messageDb.setItem("corlist", useritem)
      await this.$messageDb.setItem("grouplist", groupitem)

    },
    changeShow(type) {
      // console.log("通知切换栏")
      this.$emit('setShow', { type: type, show: true })
    },
    getHw(item) {
      // console.log(item.avatarurls)
      // console.log(parseInt(40/item.avatarurls.length))
      return parseInt(40 / item.avatarurls.length)
    },
    gotoGroup(e){
      console.log("切换:",e)
      this.$router.push({ name: "Crowd", params: { id: 2, ir: true, itemId:e, chatType: "groupChat" } })
    },
    updateInfos(obj) {
      if (obj.r == "zd") {
        //置顶
        console.log("更新置顶状态", obj.id)
        console.log("更新置顶状态", obj.value)
        if (obj.value) {
          //置顶
          if (storage.has("chatGroupTop")) {
            //存在取出
            let toplist = storage.get("chatGroupTop").split(",")
            console.log("置顶列表", toplist)
            toplist.push(obj.id)
            storage.set("chatGroupTop", toplist.join(","))
          } else {
            storage.set("chatGroupTop", obj.id)
          }
          ConversationUtils.updateTopListForSave(this.listItem, obj.id)
        } else {
          //取消置顶
          if (storage.has("chatGroupTop") && storage.get("chatGroupTop").split(",").includes(obj.id)) {
            //取消置顶
            let toplist = storage.get("chatGroupTop").split(",")
            let newtoplist = toplist.filter(e => e !== obj.id)
            if (newtoplist.length > 0) {
              storage.set("chatGroupTop", newtoplist.join(","))
            } else {
              storage.remove("chatGroupTop")
            }
            ConversationUtils.updateTopListForDelete(this.listItem, obj.id)
          }
        }
      }
      if (obj.r == "mdr") {
        //免打扰
        console.log("更新免打扰状态", obj.id)
        console.log("更新免打扰状态", obj.value)
        this.setMdr(obj.value, obj.id)
      }
      if (obj.r == "qz") {
        //群组
        console.log("更新群组状态", obj.id)
        console.log("更新群组状态", obj.value)
        this.leaveGroupMessage(obj.id)
      }
      if (obj.r == "note") {
        //群组
        console.log("更新群组状态", obj.id)
        console.log("更新群组状态", obj.value)
        this.upGroupNote(obj.id, obj.value)
      }
    },
    async upGroupNote(groupId, value) {
      this.listItem.forEach((e, i) => {
        if (e.id == groupId) {
          e.groupnameold = value
        }
      });
      let res = await this.$messageDb.getItem("grouplist")
      res.forEach(e => {
        if (e.id == groupId) {
          e.groupnameold = value
        }
      });
      await this.$messageDb.setItem("grouplist", res)
    },
    // //更新置顶排序
    // updateTopList(){

    //   //获取置顶id
    //   let topId = ""
    //   if(this.pageid == 1){
    //     //聊天置顶
    //     if(storage.has("chatTop")){
    //       topId = storage.get("chatTop").split(",")[storage.get("chatTop").split(",").length-1]
    //     }
    //   }
    //   if(this.pageid == 2){
    //     //群组置顶
    //     if(storage.has("chatGroupTop")){
    //       topId = storage.get("chatGroupTop").split(",")[storage.get("chatGroupTop").split(",").length-1]
    //     }
    //   }
    //   console.log(topId)
    //   if(topId!=''){
    //     //更新置顶信息
    //     let obj = {}
    //     this.listItem.forEach((e,i) => {
    //         if(e.id == topId){
    //           obj = e
    //           this.listItem.splice(i,1)
    //         }
    //     });
    //     this.listItem.unshift(obj)
    //   }
    // },
    //获取免打扰状态
    getMdName() {
      if (this.acitonItems.mdtype == "NONE") {
        return "取消免打扰"
      } else {
        return "设置免打扰"
      }
    },
    //获取置顶状态
    getZdStatus(item){
      //会话置顶
     
      if (this.pageid == 1) {
        //会话置顶
        if (storage.has("chatTop") && storage.get("chatTop").split(",").includes(item.id)) {
          return true
        } else {
          return false
        }
      }
      if (this.pageid == 2) {
        //群聊置顶
        if (storage.has("chatGroupTop") && storage.get("chatGroupTop").split(",").includes(item.id)) {
          return true
        } else {
          return false
        }
      }
    },
    //获取置顶状态
    getZdName() {
      if (this.pageid == 1) {
        //会话置顶
        if (storage.has("chatTop") && storage.get("chatTop").split(",").includes(this.acitonItems.id)) {
          return "取消置顶"
        } else {
          return "置顶"
        }
      }
      if (this.pageid == 2) {
        //群聊置顶
        if (storage.has("chatGroupTop") && storage.get("chatGroupTop").split(",").includes(this.acitonItems.id)) {
          return "取消置顶"
        } else {
          return "置顶"
        }
      }
    },
    //根据群信息获取操作名称
    getActionName() {
      if (this.acitonItems.role == 'owner') {
        return '解散群组'
      } else {
        return '离开群组'
      }
    },
    //删除所选元素
    deleteUserForUserList(i) {
      //去掉数组数据
      this.lsuserGroups.forEach((e, index) => {
        if (e.id == this.useUserList[i]['id']) {
          this.lsuserGroups.splice(index, 1)
        }
      });
      this.hyuserGroups.forEach((e, index) => {
        if (e.id == this.useUserList[i]['id']) {
          this.hyuserGroups.splice(index, 1)
        }
      });
      this.changeGroupUsers()
    },
    //数组去重
    cutarray(arr) {
      let obj = {};    //obj用来记录该项重复出现了几次
      let brr = [];    //brr是去重后的数组
      arr.forEach((item) => {
        if (obj[item.id] == undefined) {
          obj[item.id] = 1;
          brr.push(item);
        } else {
          obj[item.id] += 1;
        }
      });
      return brr;
    },
    //创建群组时选择群组用户事件合并数组
    changeGroupUsers() {
      let arrUsers = []
      arrUsers = this.lsuserGroups.concat(this.hyuserGroups);
      this.useUserList = this.cutarray(arrUsers);
      console.log(this.useUserList)
    },
    //获取好友
    async getFiledsList() {
      //获取好友列表
      this.hylist = []
      let res = await EaseChatClient.getContacts()
      let userinfo = await EaseChatClient.fetchUserInfoById(res.data)
      Object.keys(userinfo.data).forEach(item => {
        this.hylist.push({
          id: item,
          nickname: userinfo.data[item]['nickname'],
          avatarurl: userinfo.data[item]['avatarurl']
        })
      });
      console.log(this.hylist)
    },
    //获取会话列表
    async getHuihuasList() {
      //获取会话列表
      let userArray = []
      this.hhlist = []
      let res = await EaseChatClient.getConversationlist({ pageNum: 0, pageSize: 20 })
      console.log(res)
      //获取用户消息及构建列表
      res.data.channel_infos.forEach(item => {
        //判断会话发起人是谁 如果from是自己 取to 发起人不是自己 取from
        if (item.lastMessage.chatType != "groupchat") {
          if (item.lastMessage.from == storage.get('ImAcount')) {
            userArray.push(item.lastMessage.to)
          } else {
            userArray.push(item.lastMessage.from)
          }
        }
      });

      let userinfo = await EaseChatClient.fetchUserInfoById(userArray)
      console.log(userinfo)
      Object.keys(userinfo.data).forEach(item => {
        this.hhlist.push({
          id: item,
          nickname: userinfo.data[item]['nickname'],
          avatarurl: userinfo.data[item]['avatarurl']
        })
      });
      this.hhlist.forEach(e => {
        if (storage.has('header-name-bz' + e.id)) {
          e.nickname = storage.get('header-name-bz' + e.id)
        }
      })
      console.log(this.hhlist)
    },
    handleClickUser(e) {
      e.stopPropagation();
    },
    async seachList() {
      //重新拉取
      await this.menuHandle()
      if (this.pageid == 1) {
        //会话
        console.log(this.seachvalue)
        console.log(this.listItem)
        if(this.seachvalue == ''){
          return
        }
        let arr = []
        this.listItem.forEach(e => {
          if (e.nickname || e.id) {
            if(e.nickname){
              if (e.nickname.indexOf(this.seachvalue) != -1 || e.nicknameold.indexOf(this.seachvalue) != -1 || e.id.indexOf(this.seachvalue) != -1) {
                arr.push(e)
              }
            }else{
              if (e.nicknameold.indexOf(this.seachvalue) != -1 || e.id.indexOf(this.seachvalue) != -1) {
                arr.push(e)
              }
            }
          }
        });
        console.log(arr)
        this.listItem = arr
      }
      if (this.pageid == 2) {
        //群组
        console.log(this.seachvalue)
        console.log(this.listItem)
        let arr = []
        this.listItem.forEach(e => {
          if (e.groupname) {
            if (e.groupname.indexOf(this.seachvalue) != -1 || e.groupnameold.indexOf(this.seachvalue) != -1) {
              arr.push(e)
            }
          }
        });
        console.log(arr)
        this.listItem = arr
      }
      if (this.pageid == 3) {
        //好友
        console.log(this.seachvalue)
        console.log(this.listItem)
        let arr = []
        this.listItem.forEach(e => {
          if (e.nickname) {
            if (e.nickname.indexOf(this.seachvalue) != -1) {
              arr.push(e)
            }
          }
        });
        console.log(arr)
        this.listItem = arr
      }
    },
    async okAddCreateGroup() {
      if (this.groupindex == 1) {
        //下一步
        if (this.groupname == "") {
          message.error("请设置群组名称")
          return
        }
        this.groupindex = 2
      } else {
        //创建群组
        //获取创建群组参数
        if (this.useUserList.length == 0) {
          message.error("请选择群组成员")
          return
        }
        let obj = {
          groupname: this.groupname,
          desc: this.groupdec,
          members: [],
          public: this.groupclass == 1 ? false : true,
          approval: this.grouptype == 1 ? true : false,
          allowinvites: false,
          inviteNeedConfirm: false,
          maxusers: 200
        }
        this.useUserList.forEach(e => {
          obj.members.push(e.id)
        });
        let res = await EaseChatClient.createGroup({ data: { ...obj } })
        //更新群组列表
        this.groupvisible = false
        // await this.menuHandle()
        console.log(res)
        ConversationUtils.setGroupToLocal(res.data.groupid, this.listItem, this, this.pageid)
        message.success("创建群组成功！")
      }

    },
    ceaclCreateGroup() {
      this.groupindex == 1
      this.groupvisible = false
    },
    actionFlieds(item) {
      this.addFiledsvisible = true
      this.filedsItem = item
      this.vbtitle = item.status
    },
    actionGroups(item) {
      this.addFiledsvisible = true
      this.filedsItem = item
      this.vbtitle = "是否加入群组"
    },
    async okAddFileds() {
      //同意好友请求
      if (this.pageid == 2) {
        EaseChatClient.acceptGroupInvite({ invitee: storage.get("ImAcount"), groupId: this.filedsItem.gid });
        this.addFiledsvisible = false
        this.newGroups.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newGroups.splice(index, 1)
          }
        });
        storage.set('newGroupsList', JSON.stringify(this.newGroups))
        ConversationUtils.setGroupToLocal(this.filedsItem.gid, this.listItem, this, 2)
      }
      if (this.pageid == 3) {
        EaseChatClient.acceptContactInvite(this.filedsItem.from)
        this.addFiledsvisible = false
        this.newFileds.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newFileds.splice(index, 1)
          }
        });
        storage.set('newFiledsList', JSON.stringify(this.newFileds))
        this.menuHandle();
      }
    },
    ceaclFileds() {
      if (this.pageid == 2) {
        // EaseChatClient.rejectGroupJoinRequest({applicant: this.filedsItem.from, groupId: this.filedsItem.gid})
        EaseChatClient.rejectGroupInvite({ invitee: storage.get("ImAcount"), groupId: this.filedsItem.gid });
        this.addFiledsvisible = false
        this.newGroups.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newGroups.splice(index, 1)
          }
        });
        storage.set('newGroupsList', JSON.stringify(this.newGroups))
      }
      if (this.pageid == 3) {
        //拒绝好友请求
        EaseChatClient.declineContactInvite(this.filedsItem.from)
        this.addFiledsvisible = false
        //删除指定好友请求
        this.newFileds.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newFileds.splice(index, 1)
          }
        });
        storage.set('newFiledsList', JSON.stringify(this.newFileds))
      }
    },
    ceacl() {
      this.visible = false
    },
    async okAdd() {
      let that = this
      if (this.fuserId == "") {
        message.error('请填用户ID/群ID');
        return
      }
      // 删除好友
      // let delint = await EaseChatClient.deleteContact(this.fuserId)
      // EaseChatClient.leaveGroup({groupId:this.fuserId+""})
      let isf = 1
      //获取好友列表
      let fls = await EaseChatClient.getContacts()
      fls.data.forEach(item => {
        if (item == this.fuserId) {
          isf = 2
        }
      });
      console.log(fls)
      //获取群组列表
      let qun = await EaseChatClient.getJoinedGroups({ pageNum: 0, pageSize: 500 })
      console.log(qun)
      qun.data.forEach(item => {
        if (item.groupid == this.fuserId) {
          isf = 3
        }
      });
      //不可添加自己为好友
      if (this.fuserId == storage.get("ImAcount")) {
        message.error('无法添加自己好友');
        return
      }
      //该ID已成为您的好友！
      if (isf == 2) {
        await EaseChatClient.deleteContact(that.fuserId)
        message.error('该ID已成为您的好友！');
        return
      }
      //您已添加过群组
      if (isf == 3) {
        message.error('您已经在群组中！');
        return
      }
      let groupinfo = null
      let userinfo = null
      try {
        groupinfo = await EaseChatClient.getGroupInfo({ groupId: this.fuserId + '' })
      } catch (error) {
        userinfo = await EaseChatClient.fetchUserInfoById(this.fuserId + '')
      }

      if (groupinfo == null) {
        //添加用户好友
        console.log(userinfo)
        Modal.confirm({
          cancelText: "取消",
          okText: "确定",
          centered: true,
          title: () => '您确定发送好友申请？',
          content: () => '',
          async onOk() {
            await EaseChatClient.addContact(that.fuserId + '', "您好" + storage.get("UserName") + "请求添加好友")
          },
          onCancel() { },
        });
      } else {
        //添加群组
        console.log(groupinfo)
        Modal.confirm({
          cancelText: "取消",
          okText: "确定",
          centered: true,
          title: () => '您确定申请加入该群组？',
          content: () => '',
          async onOk() {
            await EaseChatClient.joinGroup({ groupId: that.fuserId + '', message: "您好" + storage.get("UserName") + "请求加入" + groupinfo.data[0].name })
          },
          onCancel() { },
        });
        // this.ginfo = groupinfo
      }
      this.visible = false
    },
    openFapl(e) {
      if (e == 1) {
        //好友
        this.visible = true
      }
      if (e == 2) {
        //创建群组
        this.groupindex = 1
        this.groupvisible = true
        this.getFiledsList()
        this.getHuihuasList()
      }
    },
    backgroup() {
      this.groupindex = 1
    },
    async setMdr(v, id) {
      //获取本地数据
      if (this.pageid == 1) {
        let res = await this.$messageDb.getItem("corlist")
        console.log(res)
        res.forEach(e => {
          if (e.id == id) {
            if (v) {
              e.mdtype = "NONE"
            } else {
              e.mdtype = "All"
            }
          }
        });
        console.log(res)
        await this.$messageDb.setItem("corlist", res)
      }
      if (this.pageid == 2) {
        let res = await this.$messageDb.getItem("grouplist")
        console.log(res)
        res.forEach(e => {
          if (e.id == id) {
            if (v) {
              e.mdtype = "NONE"
            } else {
              e.mdtype = "All"
            }
          }
        });
        console.log(res)
        await this.$messageDb.setItem("grouplist", res)
      }
      this.listItem.forEach(e => {
        if (e.id == id) {
          console.log(e)
          if (v) {
            e.mdtype = "NONE"
          } else {
            e.mdtype = "All"
          }
        }
      });
      this.$forceUpdate()
    },
    async aciton(v) {
      this.right = false
      let that = this
      if (v == 5) {
        console.log(this.acitonItems);
        await EaseChatClient.deleteContact(this.acitonItems.id)
        this.leaveGroupMessage(this.acitonItems.id)
      }
      if (v == 6) {
        console.log(this.acitonItems);
        //查询--用户备注
        let groupNoteInfo = await requestHttpGet(apiUrl+'/admin-api/supplier/conversation-remark/get-info', {
          conversationId: this.acitonItems.id, userId: storage.get("ImAcount")
        })
        if (groupNoteInfo.code == 0 && groupNoteInfo.data!=null) {
          this.userNote = groupNoteInfo.data.name 
          this.userNoteId = groupNoteInfo.data.id
        }else{
          this.userNote = this.acitonItems.nickname
          this.userNoteId = ''
        }
        this.notePlat = true
      }
      if (v == 4) {
        if (this.pageid == 1) {
          //删除会话
          console.log(this.acitonItems);
          EaseChatClient.deleteConversation({
            channel: this.acitonItems.id,
            chatType: this.acitonItems.chatType == 'groupChat' ? this.acitonItems.chatType : 'singleChat',
            deleteRoam: true
          })
          this.leaveGroupMessage(this.acitonItems.id)
        } else if (this.pageid == 2) {
          if (this.acitonItems.role == 'owner') {
            //解散群组
            await EaseChatClient.destroyGroup({ groupId: this.acitonItems.id + '' })
            message.success("群解散成功！")
            this.leaveGroupMessage(this.acitonItems.id)
          } else {
            //离开群组
            await EaseChatClient.leaveGroup({ groupId: this.acitonItems.id + '' })
            message.success("群退出成功！")
            this.leaveGroupMessage(this.acitonItems.id)
          }
        }
      }
      if (v == 2) {
        //标记已读
        await MessageUtils.sendMessageGoBack(this.acitonItems.id, this.type, this)
        this.acitonItems.unread_num = 0
        this.$forceUpdate();
      }
      if (v == 3) {
        //设置消息免打扰 ????????????????????
        let mdr = false
        if (this.acitonItems.mdtype == "NONE") {
          let type = this.acitonItems.chatType == 'groupChat' ? 'groupChat' : 'singleChat';
          EaseChatClient.setSilentModeForConversation({ conversationId: this.acitonItems.id, type: type, options: { paramType: 0, remindType: 'ALL' } })
          mdr = false
        } else {
          let type = this.acitonItems.chatType == 'groupChat' ? 'groupChat' : 'singleChat';
          EaseChatClient.setSilentModeForConversation({ conversationId: this.acitonItems.id, type: type, options: { paramType: 0, remindType: 'NONE' } })
          mdr = true
        }
        message.success("设置成功！")
        // this.menuHandle();
        this.setMdr(mdr, this.acitonItems.id)
      }
      if (v == 1) {
        //置顶或取消置顶
        if (this.pageid == 1) {
          //会话置顶
          if (storage.has("chatTop") && storage.get("chatTop").split(",").includes(this.acitonItems.id)) {
            //取消置顶
            let toplist = storage.get("chatTop").split(",")
            let newtoplist = toplist.filter(e => e !== this.acitonItems.id)
            if (newtoplist.length > 0) {
              storage.set("chatTop", newtoplist.join(","))
            } else {
              storage.remove("chatTop")
            }
            ConversationUtils.updateTopListForDelete(this.listItem, this.acitonItems.id)
          } else {
            //置顶
            if (storage.has("chatTop")) {
              //存在取出
              let toplist = storage.get("chatTop").split(",")
              console.log("置顶列表", toplist)
              toplist.push(this.acitonItems.id)
              storage.set("chatTop", toplist.join(","))
            } else {
              storage.set("chatTop", this.acitonItems.id)
            }
            ConversationUtils.updateTopListForSave(this.listItem, this.acitonItems.id)
          }
        }
        if (this.pageid == 2) {
          //群聊置顶
          if (storage.has("chatGroupTop") && storage.get("chatGroupTop").split(",").includes(this.acitonItems.id)) {
            //取消置顶
            let toplist = storage.get("chatGroupTop").split(",")
            let newtoplist = toplist.filter(e => e !== this.acitonItems.id)
            if (newtoplist.length > 0) {
              storage.set("chatGroupTop", newtoplist.join(","))
            } else {
              storage.remove("chatGroupTop")
            }
            ConversationUtils.updateTopListForDelete(this.listItem, this.acitonItems.id)
          } else {
            //置顶
            if (storage.has("chatGroupTop")) {
              //存在取出
              let toplist = storage.get("chatGroupTop").split(",")
              console.log("置顶列表", toplist)
              toplist.push(this.acitonItems.id)
              storage.set("chatGroupTop", toplist.join(","))
            } else {
              storage.set("chatGroupTop", this.acitonItems.id)
            }
            ConversationUtils.updateTopListForSave(this.listItem, this.acitonItems.id)
          }
        }
      }
    },
    async setItem(e) {
      console.log(e)
      this.itemId = e
      if (this.pageid == 1 || this.pageid == 3) {
        this.type = "singleChat"
      } else {
        this.type = "groupChat"
      }
      this.listItem.forEach(async item => {
        if (e == item.id) {
          if (item.unread_num > 0) {
            await MessageUtils.sendMessageGoBack(e, this.type, this)
            item.unread_num = 0
          }
          this.$forceUpdate();
        }
      });
    },
    openMenu(e, item) {
      // if(this.pageid == 3){
      //   return 
      // }
      let that = this
      this.right = false
      this.right = true
      this.acitonId = item.id
      this.acitonItems = item
      setTimeout(() => {
        if (e.clientY > 495) {
          that.$refs.rightClick.style.top = -120 + e.clientY + "px"
        } else {
          that.$refs.rightClick.style.top = 10 + e.clientY + "px"
        }
      }, 0);
    },
    down(event) {
      this.flag = true
      this.downY = event.clientY; // 获取到点击的x下标
      this.scrollTop = document.getElementById("listplant").scrollTop
    },
    move(event) {
      if (this.flag) {
        var moveY = event.clientY; // 获取移动的x轴
        var scrollY = moveY - this.downY; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
        document.getElementById("listplant").scrollTop = this.scrollTop - scrollY
      }
    },
    up(e) {
      this.flag = false
    },
    leave() {
      this.flag = false
    },
   
    async messageTon(item) {
      let that = this
      console.log('通知弹窗')
      console.log(item)
      //判断消息类型
      if (item.chatType == "groupChat") {
        //群组消息
        item.nickname = item.groupname
      }

      //消息铃声
      let playmu = true
      //消息详情
      let playinfo = true
      //消息弹窗
      let playdialog = true
      if (storage.has(storage.get("ImAcount") + "seting")) {
        let obj = JSON.parse(storage.get(storage.get("ImAcount") + "seting"))
        console.log(obj)
        playmu = obj.newMessageNotice
        playinfo = obj.noticeShowDetail
        playdialog = obj.messagePopup
      }
      console.log("播放铃声?",playmu)
      if (playmu) {
        console.log("播放铃声")
        const { isOpenPlayRing, playRing } = usePlayRing()
        if (isOpenPlayRing.value) playRing()
      }
      if (!playdialog) {
        return
      }
      let left = parseInt((window.innerWidth - 570) / 2);
      //增加消息提示
      let headericon = this.$createElement('img', {
        attrs: {
          src: item.avatarurl
        },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          marginLeft: "-14px"
        }
      })
      if (item.chatType == "groupChat") {
        headericon = this.$createElement('img', {
          attrs: {
            src: '@/assets/groupTheme.png'
          },
          style: {
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            marginLeft: "-14px"
          }
        })
        console.log(headericon)
      }
      let newnum = this.$createElement('span', { style: { backgroundColor: '#ED2616', textAlign: "center", lineHeight: "16px", marginLeft: "10px", width: "28px", height: "16px", borderRadius: '15px', color: "#fff", display: "block", marginTop: "3px", fontSize: "12px" } }, item.unread_num)
      if (item.unread_num == 0) {
        newnum = this.$createElement('span')
      }
      let content = this.$createElement('span', { style: { height: "16px" } }, item.msg)
      if (!playinfo) {
        content = "详情"
      }
      let msg = this.$createElement('div', { style: { display: "flex" } }, [content, newnum])
      notification.open({
        message: item.nickname,
        description: msg,
        onClick: function () {
          console.log("9090909090跳转会话", item)
          if (item.chatType == "groupChat") {
            let oldid = that.$route.params.id
            that.$router.push({ name: "Crowd", params: { id: 2, ir: true, itemId: item.id, chatType: "groupChat" } })
            let newid = that.$route.params.id
            if (oldid == newid) {
              that.itemId = item.id
              that.chatType = "groupChat"
              that.listItem.forEach(async itemf => {
                if (item.id == itemf.id) {
                  if (itemf.unread_num > 0) {
                    await MessageUtils.sendMessageGoBack(itemf.id, that.chatType, this)
                    itemf.unread_num = 0
                  }
                  that.$forceUpdate();
                }
              });
            }
            that.$emit('setShowItem', { type: 2, show: false })
          } else {
            let oldid = that.$route.params.id
            that.$router.push({ name: "Main", params: { id: 1, ir: true, itemId: item.id, chatType: "singleChat" } })
            let newid = that.$route.params.id
            if (oldid == newid) {
              that.itemId = item.id
              that.chatType = "singleChat"
              that.listItem.forEach(async itemf => {
                if (item.id == itemf.id) {
                  if (itemf.unread_num > 0) {
                    await MessageUtils.sendMessageGoBack(itemf.id, that.chatType, this)
                    itemf.unread_num = 0
                  }
                  that.$forceUpdate();
                }
              });
            }


            that.$emit('setShowItem', { type: 1, show: false })
          }
          notification.close(item.id)
        },
        key: item.id,
        style: {
          width: '570px',
          marginLeft:left+"px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px"
        },
        icon: headericon
      });

    },
    popNotice(headericon, areaInfo, message) {
      let that = this
      console.log(message)
      this.inter = setInterval(function () {
        var title = document.title;
        if (isShine == true) {
          if (/新/.test(title) == false) {
            document.title = '【你有新消息】';
          } else {
            document.title = '【　　　　　】';
          }
        } else {
          clearInterval(that.inter)
          document.title = titleInit;
        }
      }, 500);
      // 判断是否处于最小化状态
      console.log(document.visibilityState)
      if (document.visibilityState === 'hidden') {
        if (window.Notification) {  // 判断是否支持提醒
          //判断是否允许提醒
          if (Notification.permission === 'granted') {  // 允许的话弹出提醒
            this.pop(headericon, areaInfo, name)
          } else if (Notification.permission !== 'denied') {  // 不允许的话重新询问浏览器是否可以接收提醒
            Notification.requestPermission(function (permission) {
              this.pop(headericon, areaInfo, name)
            })
          }
        } else {
          alert('你的浏览器不支持此消息提示功能，请使用chrome内核的浏览器！')
        }
      }
    },
    pop(headericon, areaInfo, name) {
      console.log(areaInfo)
      const notification1 = new Notification('新消息：', {
        body: areaInfo,
        // icon: headericon
      })
      notification1.onshow = () => {
        console.log('通知显示了')
      }
      notification1.onclose = () => {
        console.log('通知被关闭了')
      }
      notification1.onerror = () => {
        console.log('遇到错误')
      }
    },
    async messageTonGroup(item) {
      //消息铃声
      let playmu = true
      //消息详情
      let playinfo = true
      //消息弹窗
      let playdialog = true
      console.log(item)
      
   
      if (storage.has(storage.get("ImAcount") + "seting")) {
        let obj = JSON.parse(storage.get(storage.get("ImAcount") + "seting"))
        playmu = obj.newMessageNotice
        playinfo = obj.noticeShowDetail
        playdialog = obj.messagePopup
      }
      console.log("播放铃声1?",playmu)
      if (playmu) {
        const { isOpenPlayRing, playRing } = usePlayRing()
        if (isOpenPlayRing.value) playRing()
      }
      if (!playdialog) {
        return
      }
      let left = parseInt((window.innerWidth - 570) / 2);
      //增加消息提示
      let headericon = this.$createElement('img', {
        attrs: {
          src: item.avatarurl
        },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          marginLeft: "-14px"
        }
      })

      let newnum = this.$createElement('span')
      let content = this.$createElement('span', {}, item.content)
      if (!playinfo) {
        content = item.nickname
      }
      let msg = this.$createElement('div', { style: { display: "flex" } }, [content, newnum])
      notification.open({
        message: item.nickname,
        description: msg,
        style: {
          width: '570px',
          // marginLeft: `${left}px`,
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px"
        },
        icon: headericon
      });
    },
    async messageTonFileds(item) {
      //消息铃声
      let playmu = true
      //消息详情
      let playinfo = true
      //消息弹窗
      let playdialog = true

      if (storage.has(storage.get("ImAcount") + "seting")) {
        let obj = JSON.parse(storage.get(storage.get("ImAcount") + "seting"))
        playmu = obj.newMessageNotice
        playinfo = obj.noticeShowDetail
        playdialog = obj.messagePopup
      }
      console.log("播放铃声2?",playmu)
      if (playmu) {
        const { isOpenPlayRing, playRing } = usePlayRing()
        if (isOpenPlayRing.value) playRing()
      }
      if (!playdialog) {
        return
      }
      let left = parseInt((window.innerWidth - 570) / 2);
      //增加消息提示
      let headericon = this.$createElement('img', {
        attrs: {
          src: item.avatarurl
        },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          marginLeft: "-14px"
        }
      })

      let newnum = this.$createElement('span')
      let content = this.$createElement('span', {}, item.content)
      if (!playinfo) {
        content = item.nickname
      }
      let msg = this.$createElement('div', { style: { display: "flex" } }, [content, newnum])
      notification.open({
        message: item.nickname,
        description: msg,
        style: {
          width: '570px',
          // marginLeft: `${left}px`,
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px"
        },
        icon: headericon
      });
    },
    //本地群聊更新
    async addlocalgroupold(res, e, isinster) {
      res.forEach(rl => {
        if (rl.id == e.to) {
          rl.msg = ConversationUtils.getMessageType(e.type, e.msg)
          rl.order = e.order
          if (e.fromtype == 'local') {
            rl.time = e.time
          }
        }
      });
      this.listItem.forEach(it => {
        if (it.id == e.to) {
          it.msg = ConversationUtils.getMessageType(e.type, e.msg)
          it.order = e.order
          if (e.fromtype == 'local') {
            it.time = e.time
          }
        }
      });
      await this.$messageDb.setItem("grouplist", res)
      ConversationUtils.updateTopList(this.listItem)
      this.listItem.sort((a, b) => { return b.order - a.order })
    },
    //服务器群聊更新
    async addservegroupold(res, e, isinster) {
      isinster.msg = ConversationUtils.getMessageType(e.type, e.msg)
      isinster.order = e.order
      isinster.time = e.time
      if (isinster.unread_num) {
        isinster.unread_num = isinster.unread_num + 1
      } else {
        if (e.deliver) {
          isinster.unread_num = 0
        } else {
          isinster.unread_num = 1
        }
      }
      this.listItem.forEach(it => {
        if (it.id == e.to) {
          it.msg = isinster.msg
          it.order = e.order
          it.time = e.time
          it.unread_num = isinster.unread_num
        }
      });
      if (isinster.mdtype != "NONE") {
        this.messageTon(isinster)
      }
      await this.$messageDb.setItem("grouplist", res)
      ConversationUtils.updateTopList(this.listItem)
      console.log("群聊旧会话", this.listItem)
      this.listItem.sort((a, b) => { return b.order - a.order })
      console.log("群聊旧会话", this.listItem)
    },
    //服务器单聊添加
    async addservenew(res, e) {
      //服务器单聊新会话
      //单聊新会话
      let userinfo = ""
      let iobj = {}
      userinfo = await EaseChatClient.fetchUserInfoById(e.from)
      Object.keys(userinfo.data).forEach(item => {
        iobj = {
          id: item,
          nickname: userinfo.data[item]['nickname'],
          avatarurl: userinfo.data[item]['avatarurl'],
          msg: ConversationUtils.getMessageType(e.type, e.msg),
          time: e.time,
          unread_num: 0,
          order: e.order
        }
        if (storage.has('header-name-bz' + item)) {
          iobj.nickname = storage.get('header-name-bz' + item)
        }
        console.log(iobj)
        iobj.msg = ConversationUtils.getMessageType(e.type, e.msg)
        iobj.unread_num = 0
        if (this.pageid == 1) {
          this.listItem.push(iobj)
        }
      })
      res.push(iobj)
      this.messageTon(iobj)
      await this.$messageDb.setItem("corlist", res)
      ConversationUtils.updateTopList(this.listItem)
      this.listItem.sort((a, b) => { return b.order - a.order })
    },
    //服务器单聊更新
    async addserveold(res, e, isinster) {
      //单聊旧会话
      console.log('单聊旧会话', isinster.mdtype)

      console.log('单聊旧会话', e)

      isinster.msg = ConversationUtils.getMessageType(e.type, e.msg)
      isinster.order = e.order
      isinster.time = e.time
      if (isinster.unread_num) {
        isinster.unread_num = isinster.unread_num + 1
      } else {
        if (e.deliver) {
          isinster.unread_num = 0
        } else {
          isinster.unread_num = 1
        }
      }
      this.listItem.forEach(it => {
        if (it.id == e.from) {
          it.msg = isinster.msg
          it.order = e.order
          it.unread_num = isinster.unread_num
          it.time = e.time
        }
      });
      res.forEach(rl => {
        if (rl.id == e.from) {
          console.log('找到本地单聊')
          rl.msg = isinster.msg
          rl.order = isinster.order
          rl.time = isinster.time
          console.log('找到本地单聊', rl)
        }
      });
      console.log(isinster)
      if (isinster.mdtype != "NONE") {
        this.messageTon(isinster)
      }
      console.log(res)
      console.log(this.listItem)
      await this.$messageDb.setItem("corlist", res)
      ConversationUtils.updateTopList(this.listItem)
      this.listItem.sort((a, b) => { return b.order - a.order })
    },
    //本地单聊添加
    async addlocalnew(res, e) {
      //添加新会话  单聊
      let userinfo = ""
      let iobj = {}
      userinfo = await EaseChatClient.fetchUserInfoById(e.to)
      Object.keys(userinfo.data).forEach(item => {
        iobj = {
          id: item,
          nickname: userinfo.data[item]['nickname'],
          avatarurl: userinfo.data[item]['avatarurl'],
          msg: ConversationUtils.getMessageType(e.type, e.msg),
          time: e.time,
          unread_num: 0,
          order: e.order
        }
        if (storage.has('header-name-bz' + item)) {
          iobj.nickname = storage.get('header-name-bz' + item)
        }
        console.log(iobj)
        iobj.msg = ConversationUtils.getMessageType(e.type, e.msg)
        iobj.order = e.order
        iobj.time = e.time
        iobj.unread_num = 0
        if (this.pageid == 1) {
          this.listItem.push(iobj)
        }
      })
      res.push(iobj)
      await this.$messageDb.setItem("corlist", res)
    },
    //本地单聊更新
    async addlocalold(res, e, isinster) {
      //更新旧会话 单聊
      console.log('本地旧会话')
      //更新本地旧会话
      isinster.msg = ConversationUtils.getMessageType(e.type, e.msg)
      isinster.order = e.order
      isinster.time = e.time
      this.listItem.forEach(it => {
        if (it.id == e.to) {
          it.msg = isinster.msg
          it.order = e.order
          it.unread_num = isinster.unread_num
          it.time = e.time
        }
      });
      await this.$messageDb.setItem("corlist", res)
    },
    async updateList(e) {
      //收到消息判断 单聊 群聊 好友
      if (e.chatType == "singleChat") {
        console.log("单聊")
        //消息来源 本地
        if (e.fromtype == 'local') {
          console.log("本地单聊")
          let isinster = null
          let res = await this.$messageDb.getItem("corlist")
          res.forEach(rl => {
            if (rl.id == e.to) {
              isinster = rl
            }
          });
          if (isinster == null) {
            //新会话
            this.addlocalnew(res, e)
          } else {
            //旧会话
            this.addlocalold(res, e, isinster)
          }
        } else {
          console.log("服务器单聊")
          let isinster = null
          let res = await this.$messageDb.getItem("corlist")
          res.forEach(rl => {
            if (rl.id == e.from) {
              isinster = rl
            }
          });
          if (isinster == null) {
            //新会话
            this.addservenew(res, e)
          } else {
            //旧会话
            this.addserveold(res, e, isinster)
          }

        }
      } else if (e.chatType == "groupChat") {
        console.log("群聊")

        if (e.fromtype == 'local') {
          let isinster = null
          let res = await this.$messageDb.getItem("grouplist")
          res.forEach(rl => {
            if (rl.id == e.to) {
              isinster = rl
            }
          });
          if (isinster != null) {
            //旧会话
            this.addlocalgroupold(res, e, isinster)
          }
        } else {
          console.log("服务器群聊")
          let isinster = null
          let res = await this.$messageDb.getItem("grouplist")
          res.forEach(rl => {
            if (rl.id == e.to) {
              isinster = rl
            }
          });
          if (isinster != null) {
            //旧会话
            this.addservegroupold(res, e, isinster)
          }
        }
      }
    },
    async menuHandle() {
      //判断是否登录
      console.log('初始化会话', storage.get('ImAcount'))
      if (storage.get('ImAcount') == null) {
        if (this.$ipc) {
          this.$router.push('/login')
        }
        return
      }
      console.log(!EaseChatClient.isOpened())
      if (!EaseChatClient.isOpened()) {
        let IMInfo = await EaseChatClient.open({ user: storage.get('ImAcount'), pwd: storage.get('ImPassword') })
        console.log(IMInfo)
        storage.set('IMUser', storage.get('ImAcount'))
        storage.set('IMToken', IMInfo.accessToken)
        //如果是商家更新 商家店铺名称  如果是个人更新个人nick_name
        await EaseChatClient.updateUserInfo({ nickname: storage.get("UserName"), avatarurl: storage.get("UserHeader") })
        this.eventAll()
      }
      console.log(this.id)
      //处理页面动态会话类型
      if (this.id == 'main') {
        this.$route.params.id = 1
        this.type = "singleChat"
      }
      if (this.id == 'crowd') {
        this.$route.params.id = 2
        this.type = "groupChat"
      }
      if (this.id == 'friend') {
        this.$route.params.id = 3
        this.type = "singleChat"
      }
      this.pageid = this.$route.params.id
      if (this.groupNum == 0) {
        this.listItem = []
      }
      if (this.pageid == 1) {
        console.log("获取会话列表")
        this.listItem = await ConversationUtils.getlocalCor(this)
      }
      if (this.pageid == 2) {
        //获取本地审批信息列表
        if (storage.get('newGroupsList')) {
          let fileds = storage.get('newGroupsList')
          this.newGroups = JSON.parse(fileds)
        }
        this.listItem = await ConversationUtils.getlocalGroup(this)
      }
      if (this.pageid == 3) {
        //获取本地好友申请列表
        if (storage.get('newFiledsList')) {
          let fileds = storage.get('newFiledsList')
          this.newFileds = JSON.parse(fileds)
        }
        this.listItem = await ConversationUtils.getContactsList(0, 20);
      }
      //设置置顶
      // await ConversationUtils.updateTopList(this.listItem);
      // //批量获取会话免打扰信息
      // await ConversationUtils.getSilentModeForConversations(this.listItem,this);
      //设置默认消息
      console.log(this.$route.params)
      if (this.$route.params.ir) {
        console.log(this.$route.params)
        this.setItem(this.$route.params.itemId)
      } else {
        if (this.listItem.length > 0) {
          this.setItem(this.listItem[0].id)
        } else {
          this.setItem(0)
        }
      }
    },
    menuClick(e) {

    },
    setAdd() {
      this.add = !this.add
    },
    closeMb() {
      this.add = false
      this.right = false
    },
    eventAll() {
      /* message 相关监听 */
      let that = this
      // EaseChatClient.addEventHandler('handlerId', {
      //   onTextMessage: function (message) {
      //     console.log('>>>>>>>App mesage多端', message)
      //     // console.log('setMessageKey', setMessageKey(message))
      //     // that.pushNewMessage(message)
      //   },   
      // })
      console.log("初始化监听")
      EaseChatClient.addEventHandler('handlerId', {
        onConnected: () => {
          console.log("onConnected");
        },
        onDisconnected: () => {
          console.log("onDisconnected");
        },
        onTokenWillExpire: () => {
          console.log("onTokenWillExpire");
        },
        onTokenExpired: () => {
          console.log("onTokenExpired");
        },
        onTextMessage: function (message) {
          console.log('>>>>>>>App mesage', message)
          // console.log('setMessageKey', setMessageKey(message))
          that.pushNewMessage(message)
        },    // 收到文本消息。
        onEmojiMessage: function (message) {
          that.pushNewMessage(message)
        },   // 收到表情消息。
        onImageMessage: function (message) {
          that.pushNewMessage(message)
        },   // 收到图片消息。
        onCmdMessage: function (message) {
          console.log('>>>>>收到命令消息', message)
        },     // 收到命令消息。
        onAudioMessage: function (message) {
          that.pushNewMessage(message)
        },   // 收到音频消息。
        onLocationMessage: function (message) {
          that.pushNewMessage(message)
        },// 收到位置消息。
        onFileMessage: function (message) {
          that.pushNewMessage(message)
        },    // 收到文件消息。
        onCustomMessage: function (message) {
          that.pushNewMessage(message)
        },  // 收到自定义消息。
        onVideoMessage: function (message) {
          that.pushNewMessage(message)
        },     // 收到视频消息。
        onRecallMessage: function (message) {
          console.log('回撤消息', message)
          // otherRecallMessage(message)
        },    // 收到消息撤回回执。
        onReadMessage: function (message) {
          console.log("收到消息已读回执", message)
          that.pushReadMessage(message)
        },
        onChannelMessage: function (message) {
          that.pushChannelMessage(message)
        },
        onReceivedMessage: function (message) {
          that.pushReceivedMessage(message)
        },
        onDeliveredMessage(message) {
          that.pushDeliverMessage(message)
        }
      })

      EaseChatClient.addEventHandler('friendListen', {
        // 收到好友邀请触发此方法。
        onContactInvited: (data) => {
          console.log('>>>>>>收到好友申请', data)
          that.pushMNewFileds(data)
        },
        // 联系人被删除时触发此方法。
        onContactDeleted: (data) => {
          console.log('>>>>收到好友关系解散', data)
          that.pushMRestFileds(data)
        },
        // 新增联系人会触发此方法。
        onContactAdded: (data) => {
          console.log('>>>>好友新增监听', data)
          that.pushMRestFileds(data)
        },
        // 好友请求被拒绝时触发此方法。
        onContactRefuse: (data) => {
          console.log('>>>>>>好友申请被拒绝', data)
        },
        // 好友请求被同意时触发此方法。
        onContactAgreed: (data) => {
          console.log('>>>>>对方同意了好友申请', data)
          that.pushMRestFileds(data)
        }
      })
      /* 群组相关监听 */
      EaseChatClient.addEventHandler('groupEvent', {
        onGroupChange: (data) => {
          console.log('>>>>>>>收到群组事件', data.type)
          if (data.type == "invite") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroups(data)
          }
          if (data.type == "joinGroupNotifications") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroups(data)
          }
          if (data.type == "direct_joined") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroupsJoin(data)
          }
          if (data.type == "removedFromGroup") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroupsRemove(data)
          }
          if (data.type == "leaveGroup") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroupsleave(data)
          }
          if (data.type == "memberJoinPublicGroupSuccess") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroupsmemberJoinSuccess(data)
          }
          if (data.type == "joinPublicGroupSuccess") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroupsjoinPublicGroupSuccess(data)
          }
          if (data.type == "joinPublicGroupDeclined") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroupsjoinPublicGroupDeclined(data)
          }
          if (data.type == "deleteGroupChat") {
            console.log('>>>>>>>收到群组事件', data)
            that.pushMNewGroupsdeleteGroupChat(data)
          }



        }
      })
    },
    async pushNewMessage(message) {
      console.log("分发收到消息：", message)
      this.popNotice(1, "新消息通知", message)
      await MessageUtils.CreateServiceMessage(message)
      this.$bus.$emit("newMessage", message);
      this.$bus.$emit("newMessageHost", message);
    },
    pushMNewFileds(message) {
      this.$bus.$emit("addFileds", message);
    },
    pushMNewGroups(message) {
      this.$bus.$emit("addGroups", message);
    },
    pushMNewGroupsJoin(message) {
      this.$bus.$emit("joinGroups", message);
    },
    pushMNewGroupsRemove(message) {
      this.$bus.$emit("RemoveGroups", message);
    },
    pushMNewGroupsleave(message) {
      this.$bus.$emit("leaveGroups", message);
    },
    pushMNewGroupsmemberJoinSuccess(message) {
      this.$bus.$emit("pushMNewGroupsmemberJoinSuccess", message);
    },
    pushMNewGroupsjoinPublicGroupSuccess(message) {
      this.$bus.$emit("pushMNewGroupsjoinPublicGroupSuccess", message);
    },
    pushMNewGroupsjoinPublicGroupDeclined(message) {
      this.$bus.$emit("pushMNewGroupsjoinPublicGroupDeclined", message);
    },
    pushMNewGroupsdeleteGroupChat(message) {
      this.$bus.$emit("pushMNewGroupsdeleteGroupChat", message);
    },

    pushMRestFileds(message) {
      this.$bus.$emit("RestFileds", message);
    },
    pushDeliverMessage(message) {
      this.$bus.$emit("pushDeliverMessage", message);
    },
    pushReceivedMessage(message) {
      this.$bus.$emit("pushReceivedMessage", message);
    },
    pushChannelMessage(message) {
      this.$bus.$emit("pushChannelMessage", message);
    },
    pushReadMessage(message) {
      this.$bus.$emit("pushReadMessage", message);
    },
  }
};
</script>
<style lang="less" scoped>
.mtop20 {
  margin-top: 18px !important;
}

.item-mdr {
  width: 18px;
  height: 18px;
}

.item-mdr-zd {
  width: 48px;
  height: 18px;
  color:#ED2616;
  font-size:12px;
  right: 20px;
  top: 3px;
  position: absolute;
}

.item-mdr img {
  width: 18px;
  height: 18px;
}

.time {
  font-size: 12px;
  color: #999;
}

.new-dot {
  width: 9px;
  height: 9px;
  opacity: 1;
  background: #ED2616;
  position: absolute;
  top: 2px;
  right: 0px;
  border-radius: 50%;
}

.f-splt {
  width: 85px;
  height: 1px;
  opacity: 1;
  background: #EEEEEE;
  margin-top: 4px;
  margin-bottom: 3px;
}

.list-plant {
  // height: 566px;
  height: calc(100% - 74px);
  overflow-x: scroll;
}

.right-click {
  position: absolute;
  width: 85px;
  height: 153px;
  border-radius: 4px;
  opacity: 1;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  top: -500px;
  left: 350px;
  font-size: 12px;
  line-height: 28px;
  padding-top: 1px;
  cursor: pointer;
}

.right-click-fileds {
  height: 28px;
}

.list-plant::-webkit-scrollbar {
  display: none;
}

.item-body {
  margin-left: 10px;
}

.list-item {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  cursor: pointer;
}

#app-menu {
  height: 100%;
  text-align: center;

  .layout-sider {
    background-color: #FAFAFA;
    width: 290px !important;
    flex: 0 0 290px !important;
    max-width: 290px !important;
    min-width: 290px !important;
    width: 290px;
    height: 100%;
    overflow: hidden;
  }
}

.mb {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}

.list {
  width: 290px;
  height: 100%;
  background-color: #F7F7F7;
}

.action-item {
  margin-top: 26px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  width: 220px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
}

.login-input:focus {
  outline: none;
  border: 1px solid #D8D8D8;
}

.login-input::placeholder {
  color: #999999;
}

.seachgroupuser-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  background: #EEEEEE;
  width: 164px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
}

.seachgroupuser-input:focus {
  outline: none;
  border: 1px solid #D8D8D8;
}

.seachgroupuser-input::placeholder {
  color: #999999;
}


.seach-button {
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 195px;
  cursor: pointer;
}

.seach-button-group {
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 140px;
  cursor: pointer;
}

.action-edit {
  background-image: url('~@/assets/edit1.png');
  background-size: 100%;
  width: 29px;
  height: 29px;
  cursor: pointer;
  position: relative;
}

.action-edit-avicat {
  background-image: url('~@/assets/edit2.png');
  background-size: 100%;
  width: 29px;
  height: 29px;
  cursor: pointer;
  position: relative;
}

.add-plant {
  width: 70px;
  height: 54px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 60px;
  color: #222;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  padding: 8px;
  z-index: 1000;
  left: 300px;
}

.p-text:hover {
  color: #ED2616;
}

.list-item {
  width: 290px;
  height: 70px;
}

.list-item:hover {
  width: 290px;
  height: 70px;
  background: linear-gradient(90deg, rgba(223, 79, 75, 0.00) 0%, rgba(223, 79, 75, 0.10) 100%);
  border-right: 2px solid #ED2616;
}

.list-item-active {
  width: 290px;
  height: 70px;
  background: rgba(237, 38, 22, 0.08);
  border-right: none;
}

.list-splint {
  width: 261px;
  height: 1px;
  opacity: 1;
  border: 1px solid;
  border-image: linear-gradient(180deg, #DDDDDD 0%, rgba(238, 238, 238, 0.00) 99%);
}

.item-header {
  width: 40px;
  height: 40px;
  position: relative;
  margin-top: 5px;
}

.item-header-ruand {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background-color: #ED2616;
  margin-top: 5px;
}

.item-header-ruand img {
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.item-header-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.item-dot {
  width: 24px;
  height: 15px;
  border-radius: 7.5px;
  opacity: 1;
  background: #ED2616;
  color: #fff;
  font-size: 10px;
}

.item-nickname {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  width: 200px;
  text-align: left;
  letter-spacing: 0px;
  color: #222222;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.groupname {
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.item-dec-text {
  font-family: PingFangSC-Regular;
  font-size: 11px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
  text-align: left;
  height: 15px;
}

.item-dec {
  display: flex;
  justify-content: space-between;
  width: 205px;
  margin-top: 5px;
  position: relative;
}

.mtp200 {
  margin-top: 200px;
}

/deep/ .ant-modal-header {
  text-align: center;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

/deep/ .ant-modal-body {
  padding: 16px;
}

/deep/ .ant-modal-title {
  font-size: 14px;
}

/deep/ .ant-btn-primary {
  color: #fff;
  background-color: #ED2616 !important;
  border-color: #ED2616 !important;
}

.ftitle {
  height: 16px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ED2616;
  line-height: 16px;
}

.login-input-f {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #ED2616;
  width: 212px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #222;
  margin-top: 16px;
}

.login-input-f:focus {
  outline: none;
  border: 1px solid #ED2616;
}

.login-input-f::placeholder {
  color: #D8D8D8;
}

.login-input-f-ix {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #ED2616;
  width: 358px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #222;
  margin-top: 16px;
}

.login-input-f-ix:focus {
  outline: none;
  border: 1px solid #ED2616;
}

.login-input-f-ix::placeholder {
  color: #D8D8D8;
}

.login-input-f-ex {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #CCCCCC;
  width: 358px;
  height: 73px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #222;
  margin-top: 16px;
}

.login-input-f-ex:focus {
  outline: none;
  border: 1px solid #CCCCCC;
}

.login-input-f-ex::placeholder {
  color: #D8D8D8;
}





.login-button-queren {
  width: 80px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(90deg, #FE5D42 0%, #ED2616 100%);
  border: none;
  color: #fff;
  margin-top: 21px;
  cursor: pointer;
}

.login-button-quxiao {
  width: 80px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(90deg, #ccc 0%, #ccc 100%);
  border: none;
  margin-top: 21px;
  cursor: pointer;
}

/deep/ .ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ED2616;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}

/deep/ .ant-radio-inner::after {
  background-color: #ED2616;
}

/deep/ .ant-radio-checked .ant-radio-inner {
  border: 1px solid #ED2616;
}

/deep/ .ant-radio-input:hover {
  border: 1px solid #ED2616;
}

/deep/ .ant-radio-inner:hover {
  border: 1px solid #ED2616;
}

.grouptitle {
  display: flex;
  justify-content: space-between;
}

.groupback {
  width: 18px;
  height: 18px;
}

.groupclose {
  width: 18px;
  height: 18px;
}

.groupline {
  width: 100%;
  height: 1px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(238, 238, 238, 1), rgba(238, 238, 238, 0)) 1 1;
  margin-top: 20px;
}

.seachGroupUser {
  position: relative;
}

.group-left {
  width: 195px;
  border-right: 1px solid #DDDDDD;
  height: 224px;
}

.group-right {
  margin-left: 16px;
}

/deep/ .ant-collapse>.ant-collapse-item {
  border: none;
  margin-top: 5px;
}

/deep/ .ant-collapse {
  border: none;
  background-color: #fff;
  font-size: 12px;
}

/deep/ .ant-collapse-content {
  border: none;
}

/deep/ .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.group-user-list {
  margin-top: 5px;
  height: 160px;
  overflow: hidden;
  overflow-y: auto;
}

.group-user-list::-webkit-scrollbar {
  display: none;
}

/deep/ .ant-collapse-content-box {
  padding: 0px;
}

.useUser {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 144px;
}

.useUser-name {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}

.useUser-icon {
  width: 20px;
  height: 20px;
}

/deep/ .ant-checkbox-wrapper {
  font-size: 12px;
}

.header-items {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  background: #f1f1f1;
}
</style>
